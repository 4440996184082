import { axiosHttp } from './base';

export const sendMailOrderComplete = async (idClient, orderNo, cart, amt, discount, rebate, showAmt=false, showWireInstructions=false) => {
    return axiosHttp('post', '/order/sendMailOrderComplete', true, {
        idClient: idClient,
        orderNo: orderNo,
        cart: cart,
        amt: amt,
        discount: discount,
        rebate: rebate,
        showAmt: showAmt,
        showWireInstructions: showWireInstructions,
    })
    .then((res) => {
        if (res.data.message && res.data.message === "COMPLETED") {
            return "COMPLETED";
        }
    })
    .catch((err) => {
        return err;
    })
};

export const cart_calculate_best_price_total = (cart) => {
    let total = 0.00;
    for (let a in cart) {
        // if (stateDiscount) {
        //     total += stateCart[a].priceUsd * stateCart[a].qty;
        //     total += stateCart[a].feeShipSpecial * stateCart[a].qty;
        // }
        // else {                
        // }
        if (cart[a].checked) {
            total += cart[a].subtotalUsd;
        }
    }
    return total.toFixed(2);
};

export const cart_sort_listCart_en = (a, b) => {
    if (a.productTitleEn > b.productTitleEn) { return 1; }
    if (a.productTitleEn < b.productTitleEn) { return -1; }
    if (a.varTitleEn > b.varTitleEn) { return 1; }
    if (a.varTitleEn < b.varTitleEn) { return -1; }
    return 0;
};

export const cart_sort_listCart_zh = (a, b) => {
    if (a.productTitleZh > b.productTitleZh) { return 1; }
    if (a.productTitleZh < b.productTitleZh) { return -1; }
    if (a.varTitleZh > b.varTitleZh) { return 1; }
    if (a.varTitleZh < b.varTitleZh) { return -1; }
    return 0;
};

export const cart_sort_fedex_rate_table = (a, b) => {
    if (a.country_name > b.country_name) { return 1; }
    if (a.country_name < b.country_name) { return -1; }
    if (a.product > b.product) { return 1; }
    if (a.product < b.product) { return -1; }
    if (a.zip_from > b.zip_from) { return 1; }
    if (a.zip_from < b.zip_from) { return -1; }
    if (a.measurement > b.measurement) { return 1; }
    if (a.measurement < b.measurement) { return -1; }
    if (Number(a.threshold) > Number(b.threshold)) { return 1; }
    if (Number(a.threshold) < Number(b.threshold)) { return -1; }
    return 0;
};

export const cart_calculate_shipping = (cart, fedex_rate_table, country, zip, fx, multiplier_fuel, multiplier_extra) => {
    let total_weight = 0.0;
    for (let a in cart) {
        if (cart[a].checked) {
            let volume_weight = (cart[a].length * cart[a].width * cart[a].height) / 5000;
            if (volume_weight > cart[a].weight) {
                total_weight += cart[a].qty * volume_weight;
            }
            else {
                total_weight += cart[a].qty * cart[a].weight;
            }
        }
    }
    
    let cheapest_rate = {
        price: 0,
        product: '',
        zone: '',
    };

    // loop for IE
    for (let a in fedex_rate_table) {
        // this helps with identifying only one rate from each product
        if (cheapest_rate.product === fedex_rate_table[a].product) { 
            continue; 
        }

        if (country === fedex_rate_table[a].country) {
            if (fedex_rate_table[a].zip_from) {
                // if there is zip code
                if (fedex_rate_table[a].zip_from <= zip && zip <= fedex_rate_table[a].zip_to) {
                    if (total_weight <= Number(fedex_rate_table[a].threshold)) {
                        // this resolves the issue when total_weight is less tha IEF mimumum
                        if (fedex_rate_table[a].product === "IEF" && total_weight < 68) { continue; }

                        if (cheapest_rate.price === 0) {
                            if (fedex_rate_table[a].threshold.slice(-2) === '.9') {
                                cheapest_rate.total_shipping = Number(fedex_rate_table[a].price) * total_weight;
                                cheapest_rate.price = Number(fedex_rate_table[a].price);
                            }
                            else {
                                cheapest_rate.total_shipping = Number(fedex_rate_table[a].price);
                                cheapest_rate.price = Number(fedex_rate_table[a].price);
                            }
                            cheapest_rate.product = fedex_rate_table[a].product;
                            cheapest_rate.zone = fedex_rate_table[a].zone;
                            cheapest_rate.threshold = fedex_rate_table[a].threshold;
                            cheapest_rate.a = a;
                        }
                        else if (cheapest_rate.price >= Number(fedex_rate_table[a].price)) {
                            if (fedex_rate_table[a].threshold.slice(-2) === '.9') {
                                cheapest_rate.total_shipping = Number(fedex_rate_table[a].price) * total_weight;
                                cheapest_rate.price = Number(fedex_rate_table[a].price);
                            }
                            else {
                                cheapest_rate.total_shipping = Number(fedex_rate_table[a].price);
                                cheapest_rate.price = Number(fedex_rate_table[a].price);
                            }
                            cheapest_rate.product = fedex_rate_table[a].product;
                            cheapest_rate.zone = fedex_rate_table[a].zone;
                            cheapest_rate.threshold = fedex_rate_table[a].threshold;
                            cheapest_rate.a = a;
                        }
                    }
                }
            }
            else {
                // if there is no zip code
                if (total_weight <= Number(fedex_rate_table[a].threshold)) {
                    // this resolves the issue when total_weight is less tha IEF mimumum
                    if (fedex_rate_table[a].product === "IEF" && total_weight < 68) { continue; }
                        
                    if (cheapest_rate.price === 0) {
                        if (fedex_rate_table[a].threshold.slice(-2) === '.9') {
                            cheapest_rate.total_shipping = Number(fedex_rate_table[a].price) * total_weight;
                            cheapest_rate.price = Number(fedex_rate_table[a].price);
                        }
                        else {
                            cheapest_rate.total_shipping = Number(fedex_rate_table[a].price);
                            cheapest_rate.price = Number(fedex_rate_table[a].price);
                        }
                        cheapest_rate.product = fedex_rate_table[a].product;
                        cheapest_rate.zone = fedex_rate_table[a].zone;
                        cheapest_rate.threshold = fedex_rate_table[a].threshold;
                        cheapest_rate.a = a;
                        break;
                    }
                    else if (cheapest_rate.price > Number(fedex_rate_table[a].price)) {
                        if (fedex_rate_table[a].threshold.slice(-2) === '.9') {
                            cheapest_rate.total_shipping = Number(fedex_rate_table[a].price) * total_weight;
                            cheapest_rate.price = Number(fedex_rate_table[a].price);
                        }
                        else {
                            cheapest_rate.total_shipping = Number(fedex_rate_table[a].price);
                            cheapest_rate.price = Number(fedex_rate_table[a].price);
                        }
                        cheapest_rate.product = fedex_rate_table[a].product;
                        cheapest_rate.zone = fedex_rate_table[a].zone;
                        cheapest_rate.threshold = fedex_rate_table[a].threshold;
                        cheapest_rate.a = a;
                    }
                }
            }
        }
    }

    let return_data =  {
        total_shipping: ((1 + multiplier_fuel) * (1 + multiplier_extra) * cheapest_rate.total_shipping / fx).toFixed(2),
        fx: fx,
        price: cheapest_rate.price,
        threshold: cheapest_rate.threshold,
        product: cheapest_rate.product,
        zone: cheapest_rate.zone,
        total_weight: total_weight,
        a: cheapest_rate.a,
    };

    // this avoids displaying NaN
    if (isNaN(return_data.total_shipping)) {
        return_data.total_shipping = 0;
    }

    // console.log(return_data);

    return return_data;
};

export const cart_validate_reasonCannotPay = (cart) => {
    let reasonCannotPay = null;
    let count_checked = 0;
    for (let a in cart) {
        if (cart[a].checked) {
            count_checked++;

            if (cart[a].hasInquiry) {
                reasonCannotPay = 'inquiry-';
            }
            else if (cart[a].hasShipSpecial) {
                reasonCannotPay = 'shipspecial-' + cart[a].productTitleEn;
            }
            else if (cart[a].hasOverInventory) {
                reasonCannotPay = 'overinventory-' + (cart[a].productTitleEn + ' - ' + cart[a].varTitleEn);
            }
        }
    }
    return {
        reasonCannotPay: reasonCannotPay,
        count_checked: count_checked,
    };
};