// props.show : true or false
// props.onClickModalClose : 
// props.closeByBackdropClick : true or false

import React, { Component } from 'react';

import classes from './Modal.module.css';

import Backdrop from '../Backdrop/Backdrop';

class Modal extends Component {
    // only update when .show changes
    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.show !== this.props.show || nextProps.children !== this.props.children;
    };

    handleClickModalClose = (backdrop) => {
        if (this.props.closeByBackdropClick) {
            this.props.onClickModalClose();
        }
        else {
            if (!backdrop) {
                this.props.onClickModalClose();
            }
        }
    };

    render() {
        return (
            <React.Fragment>
                <Backdrop show={ this.props.show } clicked={ () => this.handleClickModalClose(true) } />
                <div 
                    className={ classes.Modal }
                    style={{ 
                        transform: this.props.show ? 'translateY(0)' : 'translateY(-100vh)',
                        // opacity: this.props.show ? '1' : '0', 
                        display: this.props.show ? 'block' : 'none', 
                    }}
                    onClick={ () => this.handleClickModalClose(true) }
                >
                    <div className={ classes.ModalDialog }>
                        { this.props.children }
                    </div>
                </div>
            </React.Fragment>
        );    
    };
};

export default Modal;