export const urlHostRest = 'https://wnw2-ops-demo.hardcorp.com.tw:6304';
export const urlHostEcom = 'https://wnw2-ecom-demo.hardcorp.com.tw';

export const urlBaseImgStatic = 'https://storage.googleapis.com/cdngnt/dev/img/';
// export const urlBaseImgStatic = 'https://storage.googleapis.com/cdnhardcorptw/eme/img/';

export const timezoneOffsetMinutes = 480;

export const gaPageviewTrackingId = 'UA-211615098-1';

export const pusher_key = '1b63e2370008eb49846f';
export const pusher_cluster = 'ap3';

export const hitrustPayUrl = 'https://testtrustlink.hitrust.com.tw/TrustLink/TrxReqForJava';
export const hitrustReturnUrl = 'https://wnw2-ecom-demo.hardcorp.com.tw/PayCompleteHitrust';
export const hitrustMerUpdateUrl = 'https://wnw2-ecom-demo.hardcorp.com.tw:6304/hitrust/mer_update';

// keep the below or there will be errors
export const sinopacPayUrl = 'https://eposuat.sinopac.com/HPPRequest';
export const sinopacPayCompleteHost = 'http://localhost:5099';

