const sort_newList = (a, b) => {
    if (a.level > b.level) { return 1; }
    if (a.level < b.level) { return -1; }
    if (a.sort > b.sort) { return 1; }
    if (a.sort < b.sort) { return -1; }
    return 0;
};

const sort_newList_reverse = (a, b) => {
    if (a.level > b.level) { return 1; }
    if (a.level < b.level) { return -1; }
    if (a.sort > b.sort) { return -1; }
    if (a.sort < b.sort) { return 1; }
    return 0;
};

export const initialize_categorys = (input) => {
    // first create top level parents
    // then, sort sort in desc while keeping level asc
    // loop through sorted array, and insert object after its parent
    // the result is a sorted tree in flat form

    let newList = [];
    let finalList = [];
    newList = [...input];
    newList.sort(sort_newList);
    for (var key in newList) {
        if (newList[key].level === 0) {
            finalList.push({
                ...newList[key],
            });
        }
    }
    newList = newList.filter(el => el.level !== 0);
    newList.sort(sort_newList_reverse);
    // eslint-disable-next-line
    for (var key in newList) {
        for (var keyF in finalList) {
            if (finalList[keyF].id === newList[key].idParent) {
                finalList.splice(Number(keyF) + 1, 0, {
                    ...newList[key],
                });
                break;
            }
        }
    }
    return finalList;
};
