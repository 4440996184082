// idUser={props.idUser}
// drawerToggleClicked={handleDrawerToggle}
// onClickLang={handleClickLang}
// onClickLogOut={handleClickLogOut}
// lang={props.lang}
// email={props.email}
// isVIP={props.isVIP}
// cartItemCount={stateCartItemCount}
// favoriteItemCount={stateFavoriteItemCount}

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faUser, faSearch, faShoppingCart, faBars } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

import Search from './Search';
import HeaderRight from './HeaderRight';

import "./Header.css";
import { urlBaseImgStatic } from '../../helpers/url';

const MainReactObject = (props) => {
    let history = useHistory();

    const [state_search_query, set_search_query] = useState('');

    const handle_click_logo = () => {
        // history.push('/');
        window.location.href = '/';
    };

    const handle_click_search_desktop = () => {
    };

    const handle_click_user = () => {
        if (props.idUser) {
            window.location.href = '/member';
        }
        else {
            window.location.href = '/login';
        }
    };

    const handle_click_search_toggle = (data) => {
        switch (data.switch) {
            case 'mobile':
                // if (!document.getElementById('search-mobile')) { return; }
                if (document.getElementById('search-mobile').style.display === 'flex') {
                    document.getElementById('search-mobile').style.display = 'none';
                }
                else {
                    document.getElementById('search-mobile').style.display = 'flex';
                    document.getElementById('search-mobile-input').focus();
                }        
            break;

            case 'desktop':
                // if (!document.getElementById('search-form')) { return; }
                if (document.getElementById('search-form').style.display !== 'block') {
                    document.getElementById('search-form').style.display = 'block';
                    document.getElementById('search-input').style.display = 'block';
                    document.getElementById('search-input').focus();
                    setTimeout(() => {
                        document.getElementById('search-form').style.width = '300px';
                        document.getElementById('search-form').style.transition = 'all 0.5s';
                        document.getElementById('search-input').style.width = '290px';
                        document.getElementById('search-input').style.transition = 'all 0.5s';                
                    }, 100);
                }
                else {
                    document.getElementById('search-form').style.width = '3rem';
                    document.getElementById('search-form').style.transition = 'all 0.5s';
                    document.getElementById('search-input').style.width = '3rem';
                    document.getElementById('search-input').style.transition = 'all 0.5s';
                    setTimeout(() => {
                        document.getElementById('search-form').style.display = 'none';
                        document.getElementById('search-input').style.display = 'none';                
                    }, 500);
                }        
            break;

            default:
        }
    };

    const handle_submit_search_product = (data) => {
        data.e.preventDefault();
        if (state_search_query.trim() !== '') {
            history.push('/productSearch?q=' + state_search_query);
            handle_click_search_toggle({switch:data.switch});
            set_search_query('');
        }
    };

    return (
        <div style={{backgroundColor:'var(--wnw2Background)'}}>
            <Header>
                <img src={urlBaseImgStatic + 'logo/wnw2-logo-04-trans.png'} alt="GoodsnTools" onClick={handle_click_logo} style={{width:'240px', marginLeft:'1rem', cursor:'pointer'}} />
                <div style={{display:'flex', flexDirection:'row-reverse', alignItems:'center'}}>
                    <FontAwesomeIcon icon={faShoppingCart} size='lg' onClick={props.handleRightDrawerToggle} style={{marginLeft:'1rem', marginRight:'1rem', cursor:'pointer'}} />
                    <FontAwesomeIcon icon={faUser} size='lg' onClick={handle_click_user} style={{marginLeft:'1rem', marginRight:'1rem', cursor:'pointer'}} />
                    <FontAwesomeIcon icon={faSearch} size='lg' onClick={() => handle_click_search_toggle({switch:'desktop'})} style={{marginLeft:'1rem', marginRight:'1rem', zIndex:'2', cursor:'pointer'}} />
                    <form id="search-form" onSubmit={(e) => handle_submit_search_product({e:e, switch:'desktop'})} style={{position:'relative', right:'-3rem'}}><input id="search-input" type="search" onChange={(e) => set_search_query(e.target.value)} value={state_search_query} placeholder="Product Name, Model #..." /></form>
                </div>
            </Header>

            <HeaderMobile>
                <div>
                    <FontAwesomeIcon icon={faBars} size='2x' onClick={props.drawerToggleClicked} style={{marginLeft:'1rem'}} />
                </div>
                <img src={urlBaseImgStatic + 'logo/wnw2-logo-04-trans.png'} alt="GoodsnTools" onClick={handle_click_logo} style={{width:'200px'}} />
                <div>
                    <FontAwesomeIcon icon={faSearch} size='lg' onClick={() => handle_click_search_toggle({switch:'mobile'})} style={{marginRight:'1rem'}} />
                    <FontAwesomeIcon icon={faShoppingCart} size='lg' onClick={props.handleRightDrawerToggle} style={{marginRight:'1rem'}} />
                </div>
                {/* <FontAwesomeIcon icon={ faLanguage } size="2x" onClick={ props.onClickLang } /> */}
            </HeaderMobile>

            <SearchMobile id="search-mobile">
                <FontAwesomeIcon icon={faTimes} size='lg' onClick={() => handle_click_search_toggle({switch:'mobile'})} style={{marginLeft:'1rem', marginRight:'1rem'}} />
                <form onSubmit={(e) => handle_submit_search_product({e:e, switch:'mobile'})}><input id="search-mobile-input" type="search" onChange={(e) => set_search_query(e.target.value)} value={state_search_query} placeholder="Product Name, Model #..." style={{width:'250px', outline:'0', border:'0', backgroundColor:'var(--wnw2Background)'}} /></form>
                <FontAwesomeIcon icon={faSearch} size='lg' onClick={(e) => handle_submit_search_product({e:e, switch:'mobile'})} style={{marginLeft:'1rem', marginRight:'1rem', zIndex:'2', cursor:'pointer'}} />
            </SearchMobile>
        </div>
    );
};

export default MainReactObject;

const Header = styled.header`
    display: none;

    @media (min-width: 1000px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        max-width: 1200px;
        height: 4rem;
    }
`;

const HeaderMobile = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #000000;
    width: 100%;
    height: 4rem;

    @media (min-width: 1000px) {
        display: none;
    }
`;

const SearchMobile = styled.div`
    display: none;
    align-items: center;
    border-bottom: 1px solid #000000;
    width: 100%;
    height: 4rem;
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--wnw2Background);

    @media (min-width: 1000px) {
        display: none;
    }
`;
