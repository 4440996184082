// auth.js related
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_SET_REDIRECT_PATH = 'AUTH_SET_REDIRECT_PATH';
export const AUTH_RESET_ERROR = 'AUTH_RESET_ERROR';
export const AUTH_SET_LANG = "AUTH_SET_LANG";

// category.js related
export const SET_LIST_CATEGORY = 'SET_LIST_CATEGORY';
export const CLICK_LIST_CATEGORY = 'CLICK_LIST_CATEGORY';
export const CLICK_BREADCRUMB_CATEGORY = 'CLICK_BREADCRUMB_CATEGORY';

// news.js related
export const SET_NEWS_LIST_PAGE = 'SET_NEWS_LIST_PAGE';
export const SET_NEWS_LIST_NUM_PER_PAGE = 'SET_NEWS_LIST_NUM_PER_PAGE';