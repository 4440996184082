import React from 'react';
import styled from 'styled-components';


const backdrop = (props) => {
    return (
        props.show 
        ? <Backdrop onClick={props.clicked} style={{backgroundColor:'rgba(0, 0, 0, ' + (props.opacity ? props.opacity : '0.7') + ')'}} />
        : null
    );
};

export default backdrop;

const Backdrop = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    // background-color: rgba(0, 0, 0, 0.7);
`;
