import React from 'react';

import classes from './RightDrawer.module.css';

import Backdrop from '../UI/Backdrop/Backdrop';
import CartDrawer from './CartDrawer';

const MainReactObject = (props) => {
    let attachedClasses = [classes.RightDrawer, classes.Close];
    if (props.open) {
        attachedClasses = [classes.RightDrawer, classes.Open];
    }

    return (
        <React.Fragment>
            <Backdrop show={props.open} clicked={props.handleClickCloseRightDrawer} />
            <div className={attachedClasses.join(' ')}>
                {props.open ? (
                    <CartDrawer 
                        lang={props.lang}
                        idUser={props.idUser}
                    />
                ) : null}
            </div>
        </React.Fragment>
    );
};

export default MainReactObject;