// props.onClickLang={ props.onClickLang } 
// props.onClickLogOut={ props.onClickLogOut }
// props.lang={ props.lang } 
// props.email={ props.email }
// props.isVIP={ props.isVIP }
// props.cartItemCount={ props.cartItemCount }
// props.favoriteItemCount={ props.favoriteItemCount }

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faHeart, faUserCircle, faLanguage } from '@fortawesome/free-solid-svg-icons';
import { faSmile } from '@fortawesome/free-regular-svg-icons';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Button from 'react-bootstrap/Button';

import classes from './HeaderRight.module.css';

import { urlBaseImgStatic } from '../../helpers/url';

const MainReactObject = (props) => {
    let history = useHistory();

    const handleClickCart = () => {
        history.push('/cart');
    };

    const handleClickFavorite = () => {
        history.push('/favorite');
    };

    const handleClickLogIn = () => {
        history.push('/login?redirect=' + (window.location.pathname + window.location.search).substring(1));
    };

    const handleClickRegister = () => {
        history.push('/registerPolicy');
    };

    const handleClickMember = () => {
        history.push('/member');
    };

    let login = (
        <span style={{ cursor: 'pointer' }} onClick={ handleClickLogIn }>{ props.lang === 'en' ? 'Log In' : '登入' }</span>
    );

    let register = (
        <span style={{ cursor: 'pointer' }} onClick={ handleClickRegister }>{ props.lang === 'en' ? 'Register' : '註冊' }</span>
    );

    let member = (
        <span style={{ cursor: 'pointer' }} onClick={ handleClickMember }>{ props.email === null ? null : props.email.split("@")[0] }</span>
    );

    let userIcon = <FontAwesomeIcon icon={ faUserCircle } size="lg" />;
    if (props.isVIP) {
        userIcon = (
            <img style={{ marginRight: '0.2rem', verticalAlign: 'middle' }} src={ urlBaseImgStatic + 'logo/vip_19x25.png' } />
            // <FontAwesomeIcon icon={ faSmile } size="lg" />
        )
    }

    return (
        <HeaderRight>
            <HeaderRightItem>
                <Button style={{marginTop: '0.5rem'}} variant="outline-secondary" size="sm" onClick={ props.onClickLang }>
                    <FontAwesomeIcon icon={ faLanguage } size="lg" />&nbsp;{ props.lang === 'en' ? '中文' : 'English' }
                </Button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button style={{marginTop: '0.5rem'}} variant="outline-secondary" size="sm" onClick={ handleClickFavorite }>
                    <FontAwesomeIcon icon={ faHeart } size="lg" />&nbsp;({ props.favoriteItemCount })
                </Button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button style={{marginTop: '0.5rem'}} variant="outline-secondary" size="sm" onClick={ handleClickCart }>
                    <FontAwesomeIcon icon={ faShoppingCart } size="lg" />&nbsp;({ props.cartItemCount })
                </Button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div style={{verticalAlign:'sub'}}>
                    { userIcon }&nbsp;
                    { props.email ? member : null }
                    { props.email ? null : login }
                    { props.email ? null : ' / ' }
                    { props.email ? null : register }
                </div>
            </HeaderRightItem>
        </HeaderRight>
    );
};

export default MainReactObject;

const HeaderRight = styled.div`
    width: 400px;
    margin: 0.5rem 0 0.5rem 0;
    display: flex;
    justify-content: flex-start;
    flex-flow: row;
    flex-wrap: wrap;
`;

const HeaderRightItem = styled.div`
    width: 400px;
    box-sizing: border-box;
    margin: 0;
    padding: 0 10px;
    text-align: right;

    div {
        display: inline-block;
        cursor: pointer;
    }
`;
