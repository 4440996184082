import axios from 'axios';
import jwtDecode from 'jwt-decode';

import * as actionTypes from './actionTypes';
import { urlHostRest } from '../../helpers/url';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START,
    };
};

export const authSuccess = (token, idUser, email, isVIP) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
        idUser: idUser,
        email: email,
        isVIP: isVIP
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error.response.data.message,
    };
};

export const logout = () => {
    localStorage.removeItem('token');
    // comment out removing cart and favorites so these data points are not lost when user stores items locally
    // localStorage.removeItem('cart');
    // localStorage.removeItem('favorites');
    return {
        type: actionTypes.AUTH_LOGOUT,
    };
};

export const setAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            alert('您的登入時效已過期，請重新登入');
            dispatch(logout());
        }, Number(expirationTime));
    };
};

export const login = (email, password) => {
    return dispatch => {
        dispatch(authStart());
        axios({
            method: 'post',
            url: urlHostRest + '/user/login',
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                email: email,
                password: password,
            },
        })
        .then(res => {
            // expirationDate in 24 hours
            let tokenDecoded = jwtDecode(res.data.token);
            localStorage.setItem('token', res.data.token);
            dispatch(authSuccess(res.data.token, tokenDecoded.id, tokenDecoded.email, tokenDecoded.isVIP));
            dispatch(setAuthTimeout(tokenDecoded.expiresIn));
            dispatch(setLang(tokenDecoded.lang));
        })
        .catch(err => {
            dispatch(authFail(err));
        });
    }
};

export const setAuthRedirectPath = (path) => {
    if (path === null) {
        path = window.location.pathname;
    }
    return {
        type: actionTypes.AUTH_SET_REDIRECT_PATH,
        path: path,
    };
};

export const checkAuthState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if (!token) {
            dispatch(logout());
        }
        else {
            let tokenDecoded = jwtDecode(token);
            const expirationTime = tokenDecoded.expirationTime;
            if (new Date(expirationTime) > new Date()) {
                const idUser = tokenDecoded.id;
                dispatch(authSuccess(token, idUser, tokenDecoded.email, tokenDecoded.isVIP));
            }
            else {
                dispatch(logout());
            }
        }
    };
};

export const authResetError = () => {
    return {
        type: actionTypes.AUTH_RESET_ERROR,
    };
};

export const toggleLang = () => {
    let lang = localStorage.getItem('lang');
    lang = lang === 'en' ? 'zh' : 'en';
    localStorage.setItem('lang', lang)

    return {
        type: actionTypes.AUTH_SET_LANG,
        lang: lang,
    };
};

export const setLang = (lang) => {
    localStorage.setItem('lang', lang)

    return {
        type: actionTypes.AUTH_SET_LANG,
        lang: lang,
    };
};

export const initLang = () => {
    let lang = localStorage.getItem('lang');
    if (!lang) {
        localStorage.setItem('lang', 'en');
        lang = 'en';
    }

    return {
        type: actionTypes.AUTH_SET_LANG,
        lang: lang,
    };
};