// props.onSubmitSearch={ handleSubmitSearchProduct }
// props.placeholderZh
// props.placeholderEn

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import classes from './Search.module.css';

const Search = (props) => {
    const [stateSearch, setSearch] = useState('');

    const handleChange = (value) => {
        setSearch(value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (stateSearch && stateSearch.trim() !== '') {
            props.onSubmitSearch(stateSearch);
        }
    };

    return (
        <div className={ classes.Search }>
            <form onSubmit={ (e) => handleSubmit(e) }>
                <input 
                    placeholder={ props.lang === 'en' ? props.placeholderEn : props.placeholderZh } 
                    onChange={ (e) => handleChange(e.target.value) } 
                    value={ stateSearch }
                />
                <button onClick={ (e) => handleSubmit(e) }><FontAwesomeIcon icon={ faSearch } /></button>
            </form>
        </div>
    );
};

export default Search;