export const validateError = (prev, value) => {
    let errorMessage = {};
    if (prev.rules.required && value.trim() === '') {
        errorMessage['en'] = "Required";
        errorMessage['zh'] = "必須填寫";
    }
    if (prev.rules.minLength && value.trim().length < prev.rules.minLength) {
        errorMessage['en'] = 'Cannot be less than ' + prev.rules.minLength + ' chars';
        errorMessage['zh'] = '不得少於 ' + prev.rules.minLength + ' 個字';
    }
    if (prev.rules.maxLength && value.trim().length > prev.rules.maxLength) {
        errorMessage['en'] = 'Cannot be more than ' + prev.rules.maxLength + ' chars';
        errorMessage['zh'] = '不得多於 ' + prev.rules.maxLength + ' 個字';
    }
    if (prev.rules.isEmail) {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        // const pattern = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!pattern.test(value.toLowerCase())) {
            errorMessage['en'] = 'Please enter complete email';
            errorMessage['zh'] = '請輸入完整 email';
        }
    }
    if (prev.rules.isNumeric) {
        const pattern = /^\d+$/;
        if (!pattern.test(value)) {
            errorMessage['en'] = 'Must be a number';
            errorMessage['zh'] = '必須為數字';
        }
    }
    if (prev.rules.decimalPlace === 0 && value.toString().indexOf('.') > -1) {
        errorMessage['en'] = 'Cannot have decimal point';
        errorMessage['zh'] = '不能有小數點';
    }

    return {
        ...prev,
        value: value,
        errorMessage: errorMessage,
    };
};

export const alertError = (name, state) => {
    if (state.rules.required && state.value.trim() === '') {
        return name + " 必須填寫";
    }
    if (state.rules.minLength && state.value.trim().length < state.rules.minLength) {
        return name + ' 不得少於 ' + state.rules.minLength + ' 個字';
    }
    if (state.rules.maxLength && state.value.trim().length > state.rules.maxLength) {
        return name + ' 不得多於 ' + state.rules.maxLength + ' 個字';
    }
    if (state.rules.isEmail) {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        // const pattern = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!pattern.test(state.value.toLowerCase())) {
            return name + ' 不符 email 規格';
        }
    }
    if (state.rules.isNumeric) {
        const pattern = /^\d+$/;
        if (!pattern.test(state.value)) {
            return name + ' 必須為數字';
        }
    }
};