import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { fadeInDown } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import styled from 'styled-components';

const MainReactObject = (props) => {
    const styles = {
        fadeInDown: {
            animation: '0.25s',
            animationName: Radium.keyframes(fadeInDown, 'fadeInDown'),
        },
    };

    let chevron = [];
    if (props.expandable) {
        chevron = [<Chevron key={ props.id + 'c' }><FontAwesomeIcon icon={ faChevronRight } /></Chevron>];
        if (props.expanded) {
            chevron = [<Chevron key={ props.id + 'c' }><FontAwesomeIcon icon={ faChevronDown } /></Chevron>];
        }
    }
    if (props.level > 0) {
        if (!props.expandable) {
            chevron.unshift(<Chevron key={ props.id + '_' }>&nbsp;</Chevron>);
        }
        for (let a = 0; a < props.level; a++) {
            chevron.unshift(<Chevron key={ props.id + a }>&nbsp;</Chevron>);
        }
    }

    if (props.show) {
        return (
            <StyleRoot>
            <Category 
                style={styles.fadeInDown}
                key={props.id} 
                onClick={() => props.onClickCategory(props.id)}
                href={props.expandable ? 'javascript:void(0)' : ('/category/' + props.id)}
            >
                {chevron}
                <Title key={props.id + 't'}>{props.title}</Title>
            </Category>
            </StyleRoot>
        );
    }
    else {
        return null;
    }
};

export default MainReactObject;

const Category = styled.a`
    display: flex;
    justify-content: flex-start;
    padding: 0.25rem 1rem;
    /* z-index: 201; */
    transition: transform 0.3s ease-out;
    text-decoration: none;
    color: #000000;

    :hover {
        background-color: #EEEEEE;
        text-decoration: none;
        color: #000000;
    }
`;

const Chevron = styled.div`
    width: 1rem;
    cursor: pointer;
`;

const Title = styled.div`
    cursor: pointer;
`;
