import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';

import classes from './CartDrawer.module.css';

import Button from 'react-bootstrap/Button';
import SpinnerBackdrop from '../UI/Spinner/SpinnerBackdrop';

import { urlBaseImgStatic } from '../../helpers/url';
import { axiosHttp, numberWithCommas } from '../../helpers/base';
import { 
    cart_calculate_best_price_total, 
    cart_sort_listCart_en, 
    cart_sort_listCart_zh,
    cart_validate_reasonCannotPay,
} from '../../helpers/order';

const MainReactObject = (props) => {
    let unique_key = 0;

    const [stateLoading, setLoading] = useState(false);
    const [stateCart, setCart] = useState(null);
    const [stateReasonCannotPay, setReasonCannotPay] = useState(null);

    useEffect(() => {
        for (let a in stateCart) {
            if (document.getElementById('id-qty-' + stateCart[a].idVar)) {
                document.getElementById('id-qty-' + stateCart[a].idVar).value = stateCart[a].qty;
            }
        }
        // checkCartItemStatus();
    // eslint-disable-next-line
    }, [stateCart]);

    useEffect(() => {
        populateCart();
    }, []);

    const populateCart = () => {
        // setMethod('');
        setLoading(true);

        // if there is local cart, use that
        // else use cart from db
        let cart = localStorage.getItem('cart');

        axiosHttp('post', '/order/calculateCartView', false, {
            cart: cart,
            idClient: props.idUser,
        })
        .then((res) => {
            // setDeal(res.data.listDeal);
            if (props.lang === 'en') {
                setCart(res.data.listCartView.sort(cart_sort_listCart_en));
            }
            else {
                setCart(res.data.listCartView.sort(cart_sort_listCart_zh));
            }
            // setVendorPay(res.data.vendorPay);
            // setPayCardCharge(res.data.payCardCharge);
            setLoading(false);
            
            // let showCOD = false;
            // for (let a in res.data.listCartView) {
            //     if (res.data.listCartView[a].isAllowCOD) {
            //         showCOD = true;
            //         break;
            //     }
            // }
            // if (res.data.preExistingOrdersCount > 0) {
            //     showCOD = true;
            // }
            // setShowCOD(showCOD);
        });
    };

    const handleBlurQty = (idProduct, idVar, qty, checked) => {
        let cart = localStorage.getItem('cart');

        axiosHttp('post', '/order/updateCartQtyByVar', true, {
            idClient: props.idUser,
            cart: cart,
            idProduct: idProduct,
            idVar: idVar,
            qty: qty,
            checked: checked,
        })
        .then((res) => {
            if (props.idUser === null) {
                if (res.data.cart === '') {
                    localStorage.removeItem('cart');
                }
                else {
                    localStorage.setItem('cart', res.data.cart);
                }
            }
            populateCart();
            // props.onUpdateCart();
        });
    };

    const handleCheckout = () => {
        let {reasonCannotPay, count_checked} = cart_validate_reasonCannotPay(stateCart);
        setReasonCannotPay(reasonCannotPay);

        if (reasonCannotPay !== null) {
            alert("There are items selected for checkout that are either for Price Inquiry or not enough quantity in stock.\n\nPlease contact us and we'll get you sorted!");
            return;
        }
        if (count_checked === 0) {
            alert("Please select items for checkout.");
            return;
        }
        window.location.href = '/checkout';
    };

    return (
        <React.Fragment>
            {stateLoading 
            ? <SpinnerBackdrop opacity={0.5} /> 
            : null}

            <header className={classes.Header}>
                <b>Cart ({stateCart ? stateCart.length : 0})</b>
            </header>

            <table style={{width:'95%', margin:'1rem auto'}}>
                <tbody>
                    {stateCart === null 
                    ? null 
                    : (
                        stateCart.length === 0 
                        ? (<tr><td style={{textAlign:'center'}}>No Items in Cart yet</td></tr>) 
                        : (
                            stateCart.map((item, index) => {
                                return (
                                    <tr key={unique_key++}>
                                        <td>
                                            {item.checked 
                                            ? (<FontAwesomeIcon icon={faCheckSquare} onClick={() => handleBlurQty(item.idProduct, item.idVar, item.qty, '')} size="lg" />) 
                                            : (<FontAwesomeIcon icon={faSquare} onClick={() => handleBlurQty(item.idProduct, item.idVar, item.qty, 'checked')} size="lg" />)}
                                        </td>
                                        <td style={{width:'95px', padding:'0.5rem'}}>
                                            <img width="95" src={urlBaseImgStatic + 'prod/' + item.idProduct + '-' + item.sortImgPrimary + (item.timestamp ? ('-' + item.timestamp) : '') + '-sm.png'} alt="&nbsp;&nbsp;無圖片..." />
                                        </td>
                                        <td style={{padding:'0.5rem', verticalAlign:'top', paddingBottom:'2rem'}}>
                                            <div>{props.lang === 'en' ? item.productTitleEn : item.productTitleZh}</div>
                                            <div>{props.lang === 'en' ? item.varTitleEn : item.varTitleZh}</div>
                                            <div>{item.priceUsdBest > 0 ? '$' + numberWithCommas(item.priceUsdBest.toFixed(2)) : 'Price Inquiry'}</div>
                                            <div style={{marginTop:'0.5rem', display:'flex', justifyContent:'space-between'}}>
                                                <div>
                                                    <Button onClick={() => handleBlurQty(item.idProduct, item.idVar, item.qty - 1, item.checked ? 'checked' : '')} size='sm' style={{backgroundColor:'var(--wnw2Background)', border:'0', color:'black', height:'3rem', marginTop:'-5px', marginRight:'0.5rem'}}><FontAwesomeIcon icon={faMinus} size="sm" /></Button>
                                                    <input id={'id-qty-' + item.idVar} type='number' step={1} onBlur={(e) => handleBlurQty(item.idProduct, item.idVar, e.currentTarget.value)} style={{border:'1px solid #AAAAAA', borderRadius:'5px', margin:'0', width:'3rem', height:'3rem', textAlign:'center'}} />
                                                    <Button onClick={() => handleBlurQty(item.idProduct, item.idVar, item.qty + 1, item.checked ? 'checked' : '')} size='sm' style={{backgroundColor:'var(--wnw2Background)', border:'0', color:'black', height:'3rem', marginTop:'-5px', marginLeft:'0.5rem'}}><FontAwesomeIcon icon={faPlus} size="sm" /></Button>
                                                </div>
                                                <div>
                                                    <Button onClick={() => handleBlurQty(item.idProduct, item.idVar, 0)} size='sm' style={{backgroundColor:'red', border:'0', color:'white', height:'3rem', marginTop:'-5px', marginLeft:'0.5rem'}}><FontAwesomeIcon icon={faTrashAlt} size="sm" /></Button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        )
                    )}
                </tbody>
            </table>

            <hr style={{marginTop:'3rem'}} />

            <div style={{textAlign:'center', marginBottom:'2rem'}}>
                <div style={{display:'flex', justifyContent:'space-between', width:'85%', margin:'1rem auto'}}>
                    <h3><b>Total:</b></h3>
                    <h3 style={{textAlign:'right'}}><b>${numberWithCommas(cart_calculate_best_price_total(stateCart))}</b></h3>
                </div>
                <Button onClick={() => handleCheckout()} variant='primary'>Checkout</Button>
            </div>
        </React.Fragment>
    );
};

export default MainReactObject;

const ButtonQtyAddMinus = styled.button`
    cursor: pointer;
    font-weight: bold;
    color: #000000;
    text-align: center;
    width: 2rem;
    border: none;
    // box-shadow: 2px 2px 2px #DDDDDD;
    border-radius: 5px;
    text-align: center;
    outline: none;
    background-color: var(--wnw2Background);
    // font-size: 1.2rem;
    // padding: 8px 10px;
`;
