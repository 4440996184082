import axios from 'axios';

import * as actionTypes from './actionTypes';
import { axiosCatch } from '../../helpers/base';
import { urlHostRest } from '../../helpers/url';
import { initialize_categorys } from '../../helpers/category';

export const setListCategory = (listCategory) => {
    return {
        type: actionTypes.SET_LIST_CATEGORY,
        listCategory: listCategory,
    };
};

export const updateListCategory = () => {
    return dispatch => {
        axios({
            method: 'post',
            url: urlHostRest + '/product/readCategoryAllActive',
            headers: {
                'Content-Type': 'application/json',
            },
            data: {},
        })
        .then(res => {
            const tempList = initialize_categorys(res.data);
            let newList = [];

            tempList.map(el => {
                let hasChild = tempList.map(item => { return item.idParent }).indexOf(el.id) > -1;
                newList.push({
                    'id': el.id,
                    'idParent': el.idParent,
                    'titleEn': el.titleEn,
                    'titleZh': el.titleZh,
                    'show': el.idParent === 0 ? true : false,
                    'expanded': false,
                    'expandable': hasChild ? true : false,
                    'level': el.level,
                });
            });
            dispatch(setListCategory(newList))
        })
        .catch(err => {
            axiosCatch(err);
        });
    };
}

export const clickListCategory = (id) => {
    return {
        type: actionTypes.CLICK_LIST_CATEGORY,
        id: id,
    };
};

export const clickBreadcrumbCategory = (id) => {
    return {
        type: actionTypes.CLICK_BREADCRUMB_CATEGORY,
        id: id,
    };
};