import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import NavItems from './NavItems';

const MainReactObject = (props) => {
    let history = useHistory();

    const [statePopper, setPopper] = useState({show:false});

    const popperShow = () => {
        setPopper({show:true});
    };

    const popperHide = () => {
        setPopper({show:false});
    };

    const handleClickCategory = (data) => {
        history.push('/category/' + data.id);
    };

    return (
        <React.Fragment>
            <div style={{backgroundColor:'var(--wnw2Primary)', width:'100%'}}>
                <Navbar>
                    <NavItems 
                        drawerToggleClicked={props.drawerToggleClicked} 
                        lang={props.lang}
                        popperShow={popperShow}
                        popperHide={popperHide}
                    />
                </Navbar>
            </div>
            <PopperWrapper style={{display:statePopper.show ? 'block' : 'none'}}>
                <Popper onMouseEnter={popperShow} onMouseLeave={popperHide}>
                    <PopperColumn>
                        <PopperItem onClick={() => handleClickCategory({id:3})} style={{cursor:'pointer'}}>Mold Making</PopperItem>
                        <PopperItem onClick={() => handleClickCategory({id:4})} style={{cursor:'pointer'}}>Wax Injection</PopperItem>
                        <PopperItem onClick={() => handleClickCategory({id:13})} style={{cursor:'pointer'}}>Wax Tools</PopperItem>
                        <PopperItem onClick={() => handleClickCategory({id:5})} style={{cursor:'pointer'}}>Investing</PopperItem>
                        <PopperItem onClick={() => handleClickCategory({id:14})} style={{cursor:'pointer'}}>Dewaxing</PopperItem>
                        <PopperItem onClick={() => handleClickCategory({id:6})} style={{cursor:'pointer'}}>Burnout</PopperItem>
                        <PopperItem onClick={() => handleClickCategory({id:7})} style={{cursor:'pointer'}}>Casting</PopperItem>
                        <PopperItem onClick={() => handleClickCategory({id:15})} style={{cursor:'pointer'}}>Devesting</PopperItem>
                        <PopperItem onClick={() => handleClickCategory({id:16})} style={{cursor:'pointer'}}>Finishing</PopperItem>
                    </PopperColumn>
                    <PopperColumn>
                        <PopperItem onClick={() => handleClickCategory({id:17})} style={{cursor:'pointer'}}>Motor Polishing</PopperItem>
                        <PopperItem onClick={() => handleClickCategory({id:18})} style={{cursor:'pointer'}}>Flex Shaft & Accessories</PopperItem>
                        <PopperItem>Polishing Accessories</PopperItem>
                        <PopperItem onClick={() => handleClickCategory({id:30})} style={{paddingLeft:'2rem', cursor:'pointer'}}>Brushes</PopperItem>
                        <PopperItem onClick={() => handleClickCategory({id:31})} style={{paddingLeft:'2rem', cursor:'pointer'}}>Inside Ring Polish</PopperItem>
                        <PopperItem onClick={() => handleClickCategory({id:34})} style={{paddingLeft:'2rem', cursor:'pointer'}}>Polishing Compounds</PopperItem>
                        <PopperItem onClick={() => handleClickCategory({id:33})} style={{paddingLeft:'2rem', cursor:'pointer'}}>Polishing Discs</PopperItem>
                        <PopperItem onClick={() => handleClickCategory({id:32})} style={{paddingLeft:'2rem', cursor:'pointer'}}>Polishing Wheels</PopperItem>
                        <PopperItem onClick={() => handleClickCategory({id:20})} style={{cursor:'pointer'}}>Hand Polishing</PopperItem>
                        <PopperItem onClick={() => handleClickCategory({id:21})} style={{cursor:'pointer'}}>Dust Collectors</PopperItem>
                    </PopperColumn>
                    <PopperColumn>
                        <PopperItem onClick={() => handleClickCategory({id:22})} style={{cursor:'pointer'}}>Flame Soldering</PopperItem>
                        <PopperItem onClick={() => handleClickCategory({id:23})} style={{cursor:'pointer'}}>Electrical Welding</PopperItem>
                        <PopperItem onClick={() => handleClickCategory({id:24})} style={{cursor:'pointer'}}>Plating</PopperItem>
                        <PopperItem onClick={() => handleClickCategory({id:25})} style={{cursor:'pointer'}}>Laser Marking</PopperItem>
                    </PopperColumn>
                    <PopperColumn>
                        <PopperItem onClick={() => handleClickCategory({id:26})} style={{cursor:'pointer'}}>Machine Cleaning</PopperItem>
                        <PopperItem onClick={() => handleClickCategory({id:27})} style={{cursor:'pointer'}}>Hand Cleaning</PopperItem>
                    </PopperColumn>
                    <PopperColumn>
                        <PopperItem onClick={() => handleClickCategory({id:1})} style={{cursor:'pointer'}}>Workbench</PopperItem>
                        <PopperItem onClick={() => handleClickCategory({id:1})} style={{cursor:'pointer'}}>Bench Tools</PopperItem>
                        <PopperItem onClick={() => handleClickCategory({id:1})} style={{cursor:'pointer'}}>Bench Lamps</PopperItem>
                    </PopperColumn>
                </Popper>
            </PopperWrapper>
        </React.Fragment>
    );
};

export default MainReactObject;

const PopperItem = styled.div`
    padding: 5px 15px;

    :hover {
        background-color: rgba(var(--wnw2BackgroundRgb), 0.3);
    }
`;

const PopperColumn = styled.div`
    width: 240px;
`;

const Popper = styled.div`
    position: absolute;
    width: 1200px;
    background-color: white;
    z-index: 2;
    border: 1px solid #CCCCCC;
    display: flex;
`;

const PopperWrapper = styled.div`
    display: none;

    @media (min-width: 1000px) {
        // display: block;
        width: 1200px;
        margin: 0 auto;
    }
`;

const Navbar = styled.nav`
    display: none;

    @media (min-width: 1000px) {
        height: 40px;
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        box-sizing: border-box;
        z-index: 90;    
    }
`;

