import React from 'react';

import classes from './Spinner.module.css';

const spinner = (props) => {
    let borderLeft = props.back ? '1.1em solid ' + props.back : '1.1em solid #FFFFFF';
    return (
        <div className={ classes.Container }>
            <span className={ classes.Loader } style={{ borderLeft: borderLeft }}></span>
            <span className={ classes.Text }>{ props.lang === 'en' ? 'Loading...' : '處理中...' }</span>
        </div>
    );
};

export default spinner;