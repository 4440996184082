import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../helpers/base';

const initialState = {
    newsListPage: 1,
    newsListNumPerPage: 10,
};

const setNewsListPage = (state, action) => {
    return updateObject(state, {
        newsListPage: action.page,
    });
};

const setNewsListNumPerPage = (state, action) => {
    return updateObject(state, {
        newsListNumPerPage: action.data,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_NEWS_LIST_PAGE: return setNewsListPage(state, action);
        case actionTypes.SET_NEWS_LIST_NUM_PER_PAGE: return setNewsListNumPerPage(state, action);
        default: return state;
    };
};

export default reducer;