// props.idUser={ props.idUser }
// props.drawerToggleClicked={ handleDrawerToggle }
// props.lang={ props.lang }
// props.isVIP={ props.isVIP }
// props.cartItemCount={ stateCartItemCount }
// props.messageNewCount={ stateMessageNewCount }
// props.onClickMessageLauncher={ handleClickMessageLauncher }


import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faShoppingCart, faUserCircle, faNewspaper, faSmile, faComment } from '@fortawesome/free-solid-svg-icons';
import { faLine, faFacebookSquare, faYoutubeSquare, faInstagramSquare} from '@fortawesome/free-brands-svg-icons';
// import { faSmile } from '@fortawesome/free-regular-svg-icons';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';

// import classes from './Footer.module.css';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from '../UI/Modal/Modal';
import { axiosHttp } from '../../helpers/base';
import { urlBaseImgStatic } from '../../helpers/url';
import { validateError } from '../../helpers/validate';

const Footer = (props) => {
    let history = useHistory();

    const [stateModalShow, setModalShow] = useState(false);
    const [stateModalContent, setModalContent] = useState(null);
    const [state_data, set_data] = useState({
        subscribe_email: '',
    });

    const now_local = new Date();
    const now_tw = new Date(now_local.getUTCFullYear(), now_local.getUTCMonth(), now_local.getUTCDate(), now_local.getUTCHours() + 8, now_local.getUTCMinutes(), now_local.getUTCSeconds());
    const timeString = (now_tw.getFullYear() + "-" + ("0" + (now_tw.getMonth() + 1)).slice(-2) + "-" + ("0" + now_tw.getDate()).slice(-2) + " " + ("0" + now_tw.getHours()).slice(-2) + ":" + ("0" + now_tw.getMinutes()).slice(-2) + ":" + ("0" + now_tw.getSeconds()).slice(-2)).substr(0, 16);
    const currentTime = useState(timeString);
    setInterval(() => {
        const now_local = new Date();
        const now_tw = new Date(now_local.getUTCFullYear(), now_local.getUTCMonth(), now_local.getUTCDate(), now_local.getUTCHours() + 8, now_local.getUTCMinutes(), now_local.getUTCSeconds());
        const timeString = (now_tw.getFullYear() + "-" + ("0" + (now_tw.getMonth() + 1)).slice(-2) + "-" + ("0" + now_tw.getDate()).slice(-2) + " " + ("0" + now_tw.getHours()).slice(-2) + ":" + ("0" + now_tw.getMinutes()).slice(-2) + ":" + ("0" + now_tw.getSeconds()).slice(-2)).substr(0, 16);
        currentTime[1](timeString);
    }, 5000);

    const handleClickNews = () => {
        history.push('/news');
    };

    const handleClickCart = () => {
        history.push('/cart');
    };

    const handleClickMember = () => {
        history.push('/member');
    };

    const handleClickAboutUs = () => {
        setModalContent(null);
        axiosHttp('post', '/var/readAboutUsByType', false, {
            type: 'content-' + props.lang
        })
        .then((res) => {
            if (res.data.results) {
                let html = '<h2 style="margin-top:0.5rem; margin-bottom:0.5rem;">' + (props.lang === 'en' ? 'About Us' : '關於我們') + '</h2><hr>';
                html += res.data.results[0].F16;
                setModalContent(ReactHtmlParser(html));
                setModalShow(true);
            }
        });
    };

    const handle_click_privacy_policy = () => {
        setModalContent(null);
        axiosHttp('post', '/var/readPrivacyPolicyByType', false, {
            type: 'content-' + props.lang
        })
        .then((res) => {
            if (res.data.results) {
                let html = '<h2 style="margin-top:0.5rem; margin-bottom:0.5rem;">' + (props.lang === 'en' ? 'Privacy Policy' : '隱私權條款') + '</h2><hr>';
                html += res.data.results[0].F16;
                setModalContent(ReactHtmlParser(html));
                setModalShow(true);
            }
        });
    };

    const handle_change = (data) => {
        switch (data.switch) {
            case 'update_data':
                set_data(prev => {
                    let next = {...prev};
                    next[data.field] = data.value;
                    return next;
                });
            break;

            default:
        }
    };

    const handle_click = (data) => {
        let res = null;
        switch (data.switch) {
            case 'subscribe':
                res = validateError({rules:{isEmail:true}}, state_data.subscribe_email.trim().toLowerCase());
                if (res.errorMessage && res.errorMessage.en) {
                    alert(res.errorMessage.en);
                    return;
                }

                axiosHttp('post', '/user/saveUserEdm', false, {
                    email: state_data.subscribe_email.trim().toLowerCase(),
                })
                .then((res) => {
                    alert('Thank you for subscribing to our newsletter!');
                });        
            break;

            default:
        }
    };

    const handleClickVIPPolicy = () => {
        setModalContent(null);
        axiosHttp('post', '/var/readVIPPolicyByType', false, {
            type: 'content-' + props.lang
        })
        .then((res) => {
            if (res.data.results) {
                let html = '<h2 style="margin-top:0.5rem; margin-bottom:0.5rem;">' + (props.lang === 'en' ? 'Become VIP' : '成為VIP') + '</h2><hr>';
                html += res.data.results[0].F16;
                setModalContent(ReactHtmlParser(html));
                setModalShow(true);
            }
        });
    };

    const handle_click_return_policy = () => {
        setModalContent(null);
        axiosHttp('post', '/var/readReturnPolicyByType', false, {
            type: 'content-' + props.lang
        })
        .then((res) => {
            if (res.data.results) {
                let html = '<h2 style="margin-top:0.5rem; margin-bottom:0.5rem;">' + (props.lang === 'en' ? 'Return Policy' : '退換貨須知') + '</h2><hr>';
                html += res.data.results[0].F16;
                setModalContent(ReactHtmlParser(html));
                setModalShow(true);
            }
        });
    };

    const handleClickShowMap = () => {
        let html = '<iframe style="width:700px; height:525px; border:0;" loading="lazy" allowfullscreen src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCIzP45FPY_vzN7l_c4q8ZF60hanhuRnRg&q=光淙金工"></iframe>';
        setModalContent(ReactHtmlParser(html));
        setModalShow(true);
    };

    const handleClickShowDirections = () => {
        let html = '<img src="' + urlBaseImgStatic + 'misc/directions-zh.jpg"/>';
        setModalContent(ReactHtmlParser(html));
        setModalShow(true);
    };

    const handle_click_shipping = () => {
        setModalContent(null);
        axiosHttp('post', '/var/readShippingPolicyByType', false, {
            type: 'content-' + props.lang
        })
        .then((res) => {
            if (res.data.results) {
                let html = '<h2 style="margin-top:0.5rem; margin-bottom:0.5rem;">' + (props.lang === 'en' ? 'Shipping' : '運輸資訊') + '</h2><hr>';
                html += res.data.results[0].F16;
                setModalContent(ReactHtmlParser(html));
                setModalShow(true);
            }
        });
    };

    let userIcon = <FontAwesomeIcon icon={ faUserCircle } size="lg" />;
    if (props.isVIP) {
        userIcon = (
            <FontAwesomeIcon icon={ faSmile } size="lg" />
        )
    }

    return (
        <footer style={{backgroundColor:'var(--wnw2Background)'}}>
            <Modal
                show={stateModalShow}
                closeByBackdropClick={true}
                onClickModalClose={() => setModalShow(false)}
            >{stateModalContent}</Modal>

            <Desktop>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                    <div style={{width:'30%', margin:'1rem 1rem'}}>
                        <div style={{marginBottom:'1rem'}}><b>Sign Up for our Newsletter</b></div>
                        <div>
                            <Form.Control type='email' value={state_data.subscribe_email} onChange={(e) => handle_change({switch:'update_data', field:'subscribe_email', value:e.target.value})} style={{width:'60%', marginRight:'1rem', display:'inline-block'}} />
                            <Button variant='primary' type="button" onClick={() => handle_click({switch:'subscribe'})} style={{verticalAlign:'unset'}}>Subscribe</Button>
                        </div>
                    </div>
                    <div style={{width:'30%', margin:'1rem 1rem'}}>
                        <div style={{marginBottom:'1rem'}}><b>Support</b></div>
                        <div onClick={() => handle_click_shipping()} style={{cursor:'pointer'}}>{props.lang === 'en' ? 'Shipping Policy' : '送貨相關'}</div>
                        <div onClick={() => handle_click_return_policy()} style={{cursor:'pointer'}}>{props.lang === 'en' ? 'Return Policy' : '退換貨須知'}</div>
                    </div>
                    <div style={{width:'30%', margin:'1rem 1rem'}}>
                        <div style={{marginBottom:'1rem'}}><b>About</b></div>
                        {/* <div>{props.lang === 'en' ? 'About Us' : '關於我們'}</div> */}
                        <div><a href='mailto:goodsntools@gmail.com?Subject=goodsntools.com%20-%20'>Contact Us!</a></div>
                        <div onClick={() => handle_click_privacy_policy()} style={{cursor:'pointer'}}>{props.lang === 'en' ? 'Privacy Policy' : '隱私權條款'}</div>
                    </div>
                </div>
                <div style={{maxWidth:'1200px', margin:'0 auto', fontSize:'0.8rem', boxSizing:'border-box'}}>Copyright &copy; Goodsntools. All rights Reserved</div>
            </Desktop>

            <Mobile>
                <div style={{width:'100%', margin:'1rem 0'}}>
                    <div><b>Sign Up for our Newsletter</b></div>
                    <div style={{marginTop:'0.5rem'}}>
                        <Form.Control type='email' value={state_data.subscribe_email} onChange={(e) => handle_change({switch:'update_data', field:'subscribe_email', value:e.target.value})} style={{width:'50%', marginRight:'1rem', display:'inline-block'}} />
                        <Button variant='primary' type="button" onClick={() => handle_click({switch:'subscribe'})} style={{verticalAlign:'unset'}}>Subscribe</Button>
                    </div>
                </div>
                <div style={{width:'100%', margin:'1rem 0'}}>
                    <div><b>Support</b></div>
                    <div onClick={() => handle_click_shipping()} style={{cursor:'pointer'}}>{props.lang === 'en' ? 'Shipping Policy' : '送貨相關'}</div>
                    <div onClick={() => handle_click_return_policy()} style={{cursor:'pointer'}}>{props.lang === 'en' ? 'Return Policy' : '退換貨須知'}</div>
                </div>
                <div style={{width:'100%', margin:'1rem 0'}}>
                    <div><b>About</b></div>
                    {/* <div>{props.lang === 'en' ? 'About Us' : '關於我們'}</div> */}
                    <div><a href='mailto:goodsntools@gmail.com?Subject=goodsntools.com%20-%20'>Contact Us!</a></div>
                    <div onClick={() => handle_click_privacy_policy()} style={{cursor:'pointer'}}>{props.lang === 'en' ? 'Privacy Policy' : '隱私權條款'}</div>
                </div>
                <div style={{width:'100%', textAlign:'center', fontSize:'0.8rem', boxSizing:'border-box'}}>Copyright &copy; Goodsntools. All rights Reserved</div>
            </Mobile>

        </footer>
    );
};

export default Footer;

const Desktop = styled.div`
    display: none;

    a {
        color: var(--wnw2Primary);
        text-decoration: none;
    }

    @media (min-width: 1000px) {
        display: block;
        box-sizing: border-box;
        max-width: 1200px;
        margin: 0 auto;
    }
`;

const Mobile = styled.div`
    box-sizing: border-box;
    background-color: var(--wnw2Background);
    width: 100%;
    text-align: center;
    padding: 1rem 0 0 0;

    a {
        color: var(--wnw2Primary);
        text-decoration: none;
    }

    @media (min-width: 1000px) {
        display: none;
    }
`;

