import React from 'react';
import { Helmet } from 'react-helmet';

import Layout from './containers/Layout/Layout';

const App = () => {
    // this prevents user from accessing using IP
    if (window.location.hostname == '104.155.226.250') {
        window.location.href = 'http://www.950tools.com';
    }
    return (
        <React.Fragment>
        {/* <div className="App">
            <Helmet>
            <title>Goodsntools</title>
            <meta
                name="description"
                content="光淙金工提供專業 金工工具｜珠寶清潔保養｜脫蠟鑄造｜精密微電焊｜震盪研磨｜蠟雕工具｜工作桌、燈具、放大鏡｜珠寶首飾製作、珠寶零售專業用品及設備供應，同時也提供金工設備、工具線上教學服務"
            />
            <meta 
                name="keyword"
                content="金工工具,goldsmith,handmade,DIY jewelery,jeweller's tools,jewelry equipment,lost wax casting,wax pattern,accessory,jewelry,925 silver,sterlin silver,gold,copper,craft work,RIO GRANDE,STULLER,GESSWEIN,jewelry production tool,豐興行,工具張,金寶山,jbs1937,冠生工具行,寶宮銀樓,飛旗,金祥吉,昌州行,光淙,W&W,ART64,草山金工,東方金工,錦安商號,鋼咕工具王,亞洲工業鑽石,高興記,金屬工藝,金工,創作,DIY,文創,原創,手創,手工藝,銀飾,擺飾,金屬藝品,銅飾,925銀,黃銅,紅銅,純銅,9K,14K,18K,24K,5G金,硬金,純黃金,999,750,585"
            />
            <meta 
                property="fb:app_id"
                content="4086844908003881"
            />
            <meta
                property="og:description"
                content="光淙金工提供專業 金工工具｜珠寶清潔保養｜脫蠟鑄造｜精密微電焊｜震盪研磨｜蠟雕工具｜工作桌、燈具、放大鏡｜珠寶首飾製作、珠寶零售專業用品及設備供應，同時也提供金工設備、工具線上教學服務"
            />
            <meta
                property="og:image"
                content="https://storage.googleapis.com/cdnwnw/prod/img/misc/ogimage2022.jpg"
            />
            <meta
                property="og:url"
                content="https://www.950tools.com"
            />
            <meta
                property="og:title"
                content="光淙金工購物網"
            />
            <meta
                property="og:type"
                content="website"
            />
            </Helmet>
        </div> */}
        <div>
            <Layout>
            </Layout>
        </div>
        </React.Fragment>
    );
}

export default App;
