import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../helpers/base';

const initialState = {
    listCategory: null,
};

const setListCategory = (state, action)  => {
    return updateObject(state, {
        listCategory: action.listCategory,
    });
};

const clickListCategory = (state, action) => {
    let id = action.id;
    let newList = [...state.listCategory];
    let idParent = null;
    let level = null;
    let expanding = null;
    for (let a in newList) {
        if (newList[a].id === id) {
            newList[a].expanded = !newList[a].expanded
            expanding = newList[a].expanded;
            idParent = newList[a].id;
            level = newList[a].level;
            break;
        }
    }
    // expanding==true implies tree is opening
    // so show all children nodes with same idParent
    if (expanding) {
        for (let b in newList) {
            if (newList[b].idParent === idParent) {
                newList[b].show = true;
            }
        }    
    }
    // expanding==false implies tree is collapsing
    // so go down list and when unshow all nodes below where same idParent occurs, and stop when level is no longer greater (implying it's next category)
    else {
        let start = false;
        for (let b in newList) {
            if (newList[b].idParent === idParent) {
                start = true;
            }
            if (start) {
                if (newList[b].level > level) {
                    newList[b].show = false;
                    newList[b].expanded = false;
                }
                else {
                    start = false;
                }
            }
        }    
    }

    return updateObject(state, {
        listCategory: newList,
    });
};

const clickBreadcrumbCategory = (state, action) => {
    let id = action.id;
    let newList = [...state.listCategory];
    let idParent = null;
    let idSelf = null;
    // expand only the selected category, collapse all others
    // remember its idParent
    for (let a in newList) {
        if (newList[a].id === id) {
            newList[a].expanded = true;
            newList[a].show = true;
            idParent = newList[a].idParent;
            idSelf = newList[a].id;
        }
        else {
            newList[a].expanded = false;
            newList[a].show = newList[a].idParent === 0 ? true : false;
        }
    }
    // open same level, and one level down
    for (let b in newList) {
        if (newList[b].idParent === idParent || newList[b].idParent === idSelf) {
            newList[b].show = true;
        }
    }    

    return updateObject(state, {
        listCategory: newList,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_LIST_CATEGORY: return setListCategory(state, action);
        case actionTypes.CLICK_LIST_CATEGORY: return clickListCategory(state, action);
        case actionTypes.CLICK_BREADCRUMB_CATEGORY: return clickBreadcrumbCategory(state, action);
        default: return state;
    }
};

export default reducer;